import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { 
  putSelfPassword, 
} from '../../../../apis/admin/self';

const Password = () => {
  const [passwordLama, setPasswordLama] = useState("");
  const [passwordBaru, setPasswordBaru] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const passwordLamaOnChange = (e) => {
    const { value } = e.target;

    setPasswordLama(value); 
  }

  const passwordBaruOnChange = (e) => {
    const { value } = e.target;

    setPasswordBaru(value);
  }

  const editOnSubmit = async(e) => {
    e.preventDefault();

    setSubmitted(true);

    try {
      const result = await putSelfPassword({
        password_baru: passwordBaru,
        password_lama: passwordLama,
      });
// console.log(result);
      if (result.error===false) {
        setPasswordBaru("");
        setPasswordLama("");

        toast.success(
          <span className="text-white text-break">{result.message}</span>,
          {}
        );
      } else {
        toast.error(
          <span className="text-white text-break">{result.message}</span>,
          {}
        );        
      }

      setSubmitted(false);
    } catch (error) {
      console.log(error);
      setSubmitted(false);
    }
  }

  return (
    <>
      <div className="tab-pane fade" id="bordered-justified-profile" role="tabpanel" aria-labelledby="profile-tab">
      <form
          onSubmit={editOnSubmit}
        >
          <div className="row mb-3 mt-3">
            <label htmlFor="edit_password_lama" className="col-sm-4 col-form-label">Password Lama</label>
            <div className="col-sm-8">
              <input type="password" className="form-control" id="edit_password_lama" placeholder="Password lama" value={passwordLama}
                onChange={passwordLamaOnChange}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="edit_password_baru" className="col-sm-4 col-form-label">Password Baru</label>
            <div className="col-sm-8">
              <input type="password" className="form-control" id="edit_password_baru" placeholder="Password baru" value={passwordBaru}
                onChange={passwordBaruOnChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-3 mt-lg-1">
              <button type="submit" className="btn btn-success w-100" disabled={submitted}>
                {submitted===false ? (
                  <>
                    <i className="fas fa-check me-1"></i>Simpan
                  </>
                ) : (
                  <>
                    <i className="fa fa-circle-notch fa-spin me-1"></i>Loading
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default Password;