import axios from 'axios';
import Cookies from 'js-cookie';

const apiUrl = process.env.REACT_APP_API_URL;

export const postLogin = async(data) => {
  try {
    const response = await axios.post(`${apiUrl}/login`, 
      data,
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const postLogout = async() => {
  const { token } = Cookies.get();

  try {
    const response = await axios.post(`${apiUrl}/logout`, 
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+token,
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}