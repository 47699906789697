import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSelfLoggedContext } from '../../../../contexts/selfLogged';
import { 
  putSelf, 
} from '../../../../apis/admin/self';

const Data = () => {
  const [selfLogged, setSelfLogged] = useSelfLoggedContext();
  const [namaLengkap, setNamaLengkap] = useState(selfLogged.namaLengkap);
  const [username, setUsername] = useState(selfLogged.username);
  const [privileges, setPrivileges] = useState(selfLogged.privileges);
  const [submitted, setSubmitted] = useState(false);

  const namaLengkapOnChange = (e) => {
    const { value } = e.target;

    setNamaLengkap(value);
  }

  const usernameOnChange = (e) => {
    const { value } = e.target;

    setUsername(value);
  }

  const privilegesOnChange = (e) => {
    const { value } = e.target;

    setPrivileges(value);
  }

  const editOnSubmit = async(e) => {
    e.preventDefault();

    setSubmitted(true);

    try {
      const result = await putSelf({
        nama_lengkap: namaLengkap,
        username: username,
        privileges: privileges,
      });
// console.log(result);
      if (result.error===false) {
        toast.success(
          <span className="text-white text-break">{result.message}</span>,
          {}
        );
      } else {
        toast.error(
          <span className="text-white text-break">{result.message}</span>,
          {}
        );        
      }

      setSubmitted(false);
    } catch (error) {
      console.log(error);
      setSubmitted(false);
    }
  }

  useEffect(() => {
    

    return () => {
      
    }
  }, []);
  

  return (
    <>
      <div className="tab-pane fade show active" id="bordered-justified-home" role="tabpanel" aria-labelledby="home-tab">
        <form
          onSubmit={editOnSubmit}
        >
          <div className="row mb-3 mt-3">
            <label htmlFor="edit_nama_lengkap" className="col-sm-4 col-form-label">Nama Lengkap</label>
            <div className="col-sm-8">
              <input type="text" className="form-control" id="edit_nama_lengkap" placeholder="Nama lengkap" value={namaLengkap}
                onChange={namaLengkapOnChange}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="edit_username" className="col-sm-4 col-form-label">Username</label>
            <div className="col-sm-8">
              <input type="text" className="form-control" id="edit_username" placeholder="Username" value={username}
                onChange={usernameOnChange}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="edit_privileges" className="col-sm-4 col-form-label">Privileges</label>
            <div className="col-sm-8">
              <input type="text" className="form-control" id="edit_privileges" placeholder="Privileges" value={privileges}
                onChange={privilegesOnChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-3 mt-lg-1">
              <button type="submit" className="btn btn-success w-100" disabled={submitted}>
                {submitted===false ? (
                  <>
                    <i className="fas fa-check me-1"></i>Simpan
                  </>
                ) : (
                  <>
                    <i className="fa fa-circle-notch fa-spin me-1"></i>Loading
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default Data;