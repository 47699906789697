import React from 'react';
import moment from 'moment';

const Dashboard = () => {
  const year = moment().format('YYYY');

  return (
    <>
      <footer id="footer" className="footer bg-white">
        <div className="copyright">
          Copyright &copy; {year} <b>Moba Plg</b> By <b>HAV</b>
        </div>
        <div className="credits">
          <strong>NiceAdmin</strong> Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
        </div>
      </footer>
    </>
  );
};

export default Dashboard;
