import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { toast } from 'react-toastify';
import $ from 'jquery';
import DataTable from 'datatables.net-bs5';

import Protected from '../../middlewares/admin/Protected';
import Layout from '../../layouts/admin/mains/Dashboard';
import EditModal from '../../components/modals/lokasi/Edit';

import {
  getLokasis,
} from '../../apis/admin/lokasi';

$.DataTable = DataTable;

function Lokasi() {
  const [lokasis, setLokasis] = useState([]);

  const getLokasisApi = async() => {
    try {
      const result = await getLokasis();

      if (result.error==false) {
        setLokasis(result.data);

        createDataTable();
      } else {
        toast.error(
          <span className="text-white">{result.message}</span>,
          {}
        );
      }
    } catch (error) {
      toast.error(
        <span className="text-white text-break">{error.message}</span>,
        {}
      );
      // console.log(error);
    }
  }

  const createDataTable = () => {
    $(document).ready(() => {
      $('#lokasi_table').DataTable({
          destroy: true,
          lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
          // scrollX: true,
          columnDefs: [{
            targets: [4],
            orderable: false,
          }]
      });
    });
  }

  const destroyDataTable = () => {
    $(document).ready(() => {
      $('#lokasi_table').DataTable().destroy();
    });
  }

  const refreshOnClick = () => {
    destroyDataTable();
    getLokasisApi();
  }

  useEffect(() => {
    getLokasisApi();

    return () => {

    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Lokasi - Admin | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>

      <div className="pagetitle">
        <h1>Lokasi</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">Lokasi</li>
          </ol>
        </nav>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-12">
            <div className="row mb-2">
              <div className="col-12">
                <button type="button" id="refresh_button" className="btn btn-primary btn-sm"
                  onClick={refreshOnClick}
                >
                  <i className="fas fa-sync-alt me-1"></i>Refresh
                </button>
              </div>
            </div>
            <table id='lokasi_table' className="table table-bordered mb-0 bg-white">
              <thead>
                <tr className="text-center">
                  <th>No</th>
                  <th>Nama</th>
                  <th>Keterangan</th>
                  <th>Tinggi Air Maksimum(cm)</th>
                  <th>
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                {lokasis.map((value, index) => {
                  return (
                    <tr
                      key={index}
                    >
                      <td>{index+1}</td>
                      <td>{value.i0001}</td>
                      <td>{value.i0002}</td>
                      <td>{value.i0003}</td>
                      <td>
                        <button type="button" className="edit_button btn btn-warning btn-sm me-1 d-inline-flex align-items-center" data-id={value.id}>
                          <i className="fas fa-edit me-1"></i>Ubah
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <EditModal 
        getLokasisApi={getLokasisApi}
        destroyDataTable={destroyDataTable}
      />
    </>
  )
}

export default Protected(Layout(Lokasi))