import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';

import { 
  getUser, 
  putUserPassword, 
} from '../../../apis/admin/user';

const Password = () => {
  const modal = useRef(null);
  const [id, setId] = useState("");
  const [passwordBaru, setPasswordBaru] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const passwordBaruOnChange = (e) => {
    const { value } = e.target;

    setPasswordBaru(value);
  }

  const editOnSubmit = async(e) => {
    e.preventDefault();

    setSubmitted(true);

    try {
      const result = await putUserPassword(id, {
        password: passwordBaru,
      });
// console.log(result);
      if (result.error===false) {
        hideModal();

        toast.success(
          <span className="text-white">{result.message}</span>,
          {}
        );
      } else {
        toast.error(
          <span className="text-white">{result.message}</span>,
          {}
        );        
      }

      setSubmitted(false);
    } catch (error) {
      toast.error(
        <span className="text-white text-break">{error.message}</span>,
        {}
      );
      // console.log(error);
      setSubmitted(false);
    }
  }

  const listener = async(e) => {
    const element = e.target;
    const parent = element.parentNode;
    var dataId = '';

    const func = async() => {
      try {
        const result = await getUser(dataId);
  // console.log(result);
        if (result.error===false) {
          const { data } = result;
  
          setId(data.id);
  
          modal.current.show();
        } else {
          toast.error(
            <span className="text-white">{result.message}</span>,
            {}
          );
        }
      } catch (error) {
        toast.error(
          <span className="text-white text-break">{error.message}</span>,
          {}
        );
        // console.log(error);
      }
    }

    if (parent.tagName=='BUTTON' && parent.classList.contains("password_button")) {
      dataId = parent.getAttribute('data-id');

      func();
    } else if (element.tagName=='BUTTON' && element.classList.contains("password_button")) {
      dataId = element.getAttribute('data-id');

      func();
    }
  }

  const hideModal = () => {
    modal.current.hide();

    setTimeout(() => {
      setId("");
      setPasswordBaru("");
    }, 1000);
  }

  useEffect(() => {
    const elementModal = document.getElementById('password_modal');
    modal.current = new Modal(elementModal, {
      keyboard: true,
    });

    document.addEventListener('click', listener);
    
    return () => {
      document.removeEventListener('click', listener)
    }
  }, []);

  return (
    <>
      <div className="modal fade" id="password_modal" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold">Formulir Password</h5>
              <div className="me-1"
                style={{ 
                  cursor: 'pointer',
                }}
                onClick={hideModal}
              >
                <i className="fas fa-times"></i>
              </div>
            </div>
            <form
              onSubmit={editOnSubmit}
            >
              <div className="modal-body">
                <div className="row mb-3">
                  <label htmlFor="add_password_baru" className="col-sm-4 col-form-label">Password Baru</label>
                  <div className="col-sm-8">
                    <input type="password" className="form-control" id="add_password_baru" placeholder="Password Baru" value={passwordBaru}
                      onChange={passwordBaruOnChange}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline-danger btn-sm" 
                  onClick={hideModal}
                >
                  <i className="fas fa-times me-1"></i>Tutup
                </button>
                <button type="submit" className="btn btn-success btn-sm" disabled={submitted}>
                  {submitted===false ? (
                    <>
                      <i className="fas fa-check me-1"></i>Simpan
                    </>
                  ) : (
                    <>
                      <i className="fa fa-circle-notch fa-spin me-1"></i>Loading
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Password;