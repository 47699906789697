import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer, Zoom } from 'react-toastify';

import { useAppLoadedContext } from '../../contexts/appLoaded';
import { useSelfLoggedContext } from '../../contexts/selfLogged';
import { useAuthSelfContext } from '../../contexts/authSelf';
import { 
  getSelf, 
} from '../../apis/admin/self';

const Protected = (Component) => {
  const Render = (props) => {
    const navigate = useNavigate();
    const [appLoaded, setAppLoaded] = useAppLoadedContext();
    const [selfLogged, setSelfLogged] = useSelfLoggedContext();
    const [authSelf, setAuthSelf] = useAuthSelfContext();

    const auth = async() => {
      const { token } = Cookies.get();

      if (token) {
        try {
          const result = await getSelf();

          if (result.error===false) {
            const { data } = result;

            if (appLoaded===false) {
              setAppLoaded(true);
            }

            setSelfLogged({
              id: data.id,
              namaLengkap: data.i0001,
              username: data.i0002,
              privileges: data.i0004,
              tingkat: data.i0005,
            });
            setAuthSelf(true);
          } else {
            toast.error(
              <span className="text-white">
                {result.message}
              </span>,
              {}
            );
            Cookies.remove('token');
            
            setTimeout(() => {
              navigate('/admin/login');
            }, 1000);
          }
        } catch (error) {
          console.log(error);
          setTimeout(() => {
            navigate('/admin/login');
          }, 1000);
        }
      } else {
        navigate('/admin/login');
      }
    }

    useEffect(() => {
      auth();

      return () => {
        setAuthSelf(false);
      }
    }, []);

    return (
      <>
        {appLoaded===true ? (
          <Component {...props} />
        ) : (
          <ToastContainer
            position="top-center"
            autoClose={false}
            hideProgressBar={true}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={false}
            progress={undefined}
            theme='colored'
            transition={Zoom}
          />
        )}
      </>
    )
  }

  return Render;
}

export default Protected;