import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';

import { 
  getLokasi, 
  putLokasi, 
} from '../../../apis/admin/lokasi';

const Edit = (props) => {
  const { getLokasisApi, destroyDataTable } = props;

  const modal = useRef(null);
  const [id, setId] = useState('');
  const [nama, setNama] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [tinggiAirMaksimumCm, setTinggiAirMaksimumCm] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const namaOnChange = (e) => {
    const { value } = e.target;

    setNama(value);
  }

  const keteranganOnChange = (e) => {
    const { value } = e.target;

    setKeterangan(value);
  }

  const tinggiAirMaksimumCmOnChange = (e) => {
    const { value } = e.target;

    setTinggiAirMaksimumCm(value);
  }

  const editOnSubmit = async(e) => {
    e.preventDefault();

    setSubmitted(true);

    try {
      const result = await putLokasi(id, {
        nama: nama,
        keterangan: keterangan,
        tinggi_air_maksimum_cm: tinggiAirMaksimumCm,
      });
// console.log(result);
      if (result.error===false) {
        hideModal();

        toast.success(
          <span className="text-white">{result.message}</span>,
          {}
        );

        destroyDataTable();
        getLokasisApi();
      } else {
        toast.error(
          <span className="text-white">{result.message}</span>,
          {}
        );        
      }

      setSubmitted(false);
    } catch (error) {
      toast.error(
        <span className="text-white text-break">{error.message}</span>,
        {}
      );
      // console.log(error);
      setSubmitted(false);
    }
  }

  const listener = async(e) => {
    const element = e.target;
    const parent = element.parentNode;
    var dataId = '';

    const func = async() => {
      try {
        const result = await getLokasi(dataId);
  
        if (result.error===false) {
          const { data } = result;
  
          setId(data.id);
          setNama(data.i0001);
          setKeterangan(data.i0002);
          setTinggiAirMaksimumCm(data.i0003);
  
          modal.current.show();
        } else {
          toast.error(
            <span className="text-white">{result.message}</span>,
            {}
          );
        }
      } catch (error) {
        toast.error(
          <span className="text-white text-break">{error.message}</span>,
          {}
        );
        // console.log(error);
      }
    }

    if (parent.tagName=='BUTTON' && parent.classList.contains("edit_button")) {
      dataId = parent.getAttribute('data-id');

      func();
    } else if (element.tagName=='BUTTON' && element.classList.contains("edit_button")) {
      dataId = element.getAttribute('data-id');

      func();
    }
  }

  const hideModal = () => {
    modal.current.hide();

    setTimeout(() => {
      setId("");
      setNama("");
      setKeterangan("");
      setTinggiAirMaksimumCm("");
    }, 1000);
  }

  useEffect(() => {
    const elementModal = document.getElementById('edit_modal');
    modal.current = new Modal(elementModal, {
      keyboard: true,
    });

    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener)
    }
  }, []);

  return (
    <>
      <div className="modal fade" id="edit_modal" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold">Formulir Ubah</h5>
              <div className="me-1"
                style={{ 
                  cursor: 'pointer',
                }}
                onClick={hideModal}
              >
                <i className="fas fa-times"></i>
              </div>
            </div>
            <form
              onSubmit={editOnSubmit}
            >
              <div className="modal-body">
                <div className="row mb-3">
                  <label htmlFor="edit_nama" className="col-sm-4 col-form-label">Nama</label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control" id="edit_nama" placeholder="Nama" value={nama}
                      onChange={namaOnChange}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="edit_keterangan" className="col-sm-4 col-form-label">Keterangan</label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control" id="edit_keterangan" placeholder="Keterangan" value={keterangan ?? ""}
                      onChange={keteranganOnChange}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="edit_edit_tinggi_air_maksimum_cm" className="col-sm-4 col-form-label">Tinggi Air Maksimum(cm)</label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control" id="edit_tinggi_air_maksimum_cm" placeholder="Tinggi Air Maksimum Cm" value={tinggiAirMaksimumCm}
                      onChange={tinggiAirMaksimumCmOnChange}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline-danger btn-sm" 
                  onClick={hideModal}
                >
                  <i className="fas fa-times me-1"></i>Tutup
                </button>
                <button type="submit" className="btn btn-success btn-sm" disabled={submitted}>
                  {submitted===false ? (
                    <>
                      <i className="fas fa-check me-1"></i>Simpan
                    </>
                  ) : (
                    <>
                      <i className="fa fa-circle-notch fa-spin me-1"></i>Loading
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Edit;