import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const location = useLocation();

  const { pathname } = location;
  const pathnames = pathname.split('/');
  const page = pathnames[1];

  return (
    <>
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link 
              to="/home"
              className={`nav-link ${page==='home' ? '' : 'collapsed'}`}
            >
              <i className="fas fa-home"></i>
              <span>Home</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link 
              to="/akun"
              className={`nav-link ${page==='akun' ? '' : 'collapsed'}`}
            >
              <i className="fas fa-user-cog"></i>
              <span>Akun</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link 
              to="/grafik"
              className={`nav-link ${page==='grafik' ? '' : 'collapsed'}`}
            >
              <i className="fas fa-map"></i>
              <span>Grafik</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link 
              to="/riwayat"
              className={`nav-link ${page==='riwayat' ? '' : 'collapsed'}`}
            >
              <i className="fas fa-history"></i>
              <span>Riwayat</span>
            </Link>
          </li>
        </ul>
      </aside>
    </>
  );
};

export default Dashboard;
