import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';

import {
  deleteUser,
} from '../../../apis/admin/user';

const Delete = (props) => {
  const { getUsersApi, destroyDataTable } = props;

  const modal = useRef(null);
  const [id, setId] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const deleteOnSubmit = async(e) => {
    e.preventDefault();

    setSubmitted(true);

    try {
      const result = await deleteUser(id);
// console.log(result);
      if (result.error===false) {
        hideModal();

        toast.success(
          <span className="text-white">{result.message}</span>,
          {}
        );

        destroyDataTable();
        getUsersApi();
      } else {
        toast.error(
          <span className="text-white">{result.message}</span>,
          {}
        );
      }

      setSubmitted(false);
    } catch (error) {
      toast.error(
        <span className="text-white text-break">{error.message}</span>,
        {}
      );
      // console.log(error);
      setSubmitted(false);
    }
  }

  const listener = (e) => {
    const element = e.target;
    const parent = element.parentNode;
    var dataId = '';

    const func = () => {
      setId(dataId);

      modal.current.show();
    }
    
    if (parent.tagName=='BUTTON' && parent.classList.contains("delete_button")) {
      dataId = parent.getAttribute('data-id');

      func();
    } else if (element.tagName=='BUTTON' && element.classList.contains("delete_button")) {
      dataId = element.getAttribute('data-id');

      func();
    }
  }

  const hideModal = () => {
    modal.current.hide();

    setTimeout(() => {
      setId("");
    }, 1000);
  }

  useEffect(() => {
    const elementModal = document.getElementById('delete_modal');
    modal.current = new Modal(elementModal, {
      keyboard: true,
    });

    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener)
    }
  }, []);

  return (
    <>
      <div className="modal fade" id="delete_modal" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold">Formulir Hapus</h5>
              <div className="me-1"
                style={{ 
                  cursor: 'pointer',
                }}
                onClick={hideModal}
              >
                <i className="fas fa-times"></i>
              </div>
            </div>
            <form
              onSubmit={deleteOnSubmit}
            >
              <div className="modal-body">
                <div className="text-center">
                  Apakah anda yakin ingin menghapus data?
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline-danger btn-sm" 
                  onClick={hideModal}
                >
                  <i className="fas fa-times me-1"></i>Tutup
                </button>
                <button type="submit" className="btn btn-success btn-sm" disabled={submitted}>
                  {submitted===false ? (
                    <>
                      <i className="fas fa-check me-1"></i>Hapus
                    </>
                  ) : (
                    <>
                      <i className="fa fa-circle-notch fa-spin me-1"></i>Loading
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Delete;