import axios from 'axios';
import Cookies from 'js-cookie';

const apiUrl = process.env.REACT_APP_API_URL;

export const getUsers = async() => {
  const { token } = Cookies.get();

  try {
    const response = await axios.get(`${apiUrl}/admin/user`, 
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+token,
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const postUser = async(data) => {
  const { token } = Cookies.get();

  try {
    const response = await axios.post(`${apiUrl}/admin/user`, 
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+token,
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getUser = async(id) => {
  const { token } = Cookies.get();

  try {
    const response = await axios.get(`${apiUrl}/admin/user/${id}`, 
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+token,
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const putUser = async(id, data) => {
  const { token } = Cookies.get();

  try {
    const response = await axios.put(`${apiUrl}/admin/user/${id}`, 
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+token,
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const deleteUser = async(id) => {
  const { token } = Cookies.get();

  try {
    const response = await axios.delete(`${apiUrl}/admin/user/${id}`, 
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+token,
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const putUserPassword = async(id, data) => {
  const { token } = Cookies.get();

  try {
    const response = await axios.put(`${apiUrl}/admin/user/${id}/password`, 
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+token,
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}