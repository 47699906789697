import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const NotFound = () => {
  const year = moment().format('YYYY');

  return (
    <>
      <div className="container">

        <section className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
          <h1>404</h1>
          <h2 className="">Halaman tidak ditemukan</h2>
          <Link 
            to="/home"
            className="btn"
          >
            <a>
              <i className="fas fa-home me-1"></i>Home
            </a>
          </Link>
          {/* <img src={`${process.env.PUBLIC_URL}/assets/img/not-found.svg`} className="img-fluid py-5" alt="Page Not Found"/> */}
          <div className="copyright mt-5">
          Copyright &copy; {year} <b>Moba Plg</b> By <b>HAV</b>
          </div>
          <div className="credits">
            <strong>NiceAdmin</strong> Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
          </div>
        </section>

      </div>
    </>
  )
}

export default NotFound;