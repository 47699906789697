import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { toast } from 'react-toastify';

import {
  getLevelAirRiwayat,
} from '../../apis/index';

require('highcharts/modules/exporting')(Highcharts);

class Location extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      titiks: [],
      // width: 0,
    };
  }

  async getLevelAirRiwayatApi() {
    try {
      const result = await getLevelAirRiwayat({
        'position': this.props.lokasi.id,
        'start_datetime': this.props.start,
        'end_datetime': this.props.end,
      });
      // const length = result['data'].length;
      // var tempWidth = 0;

      // if (length>1000 && length<1200) {
      //   tempWidth = 50000;
      // } else if (length>800 && length<1000) {
      //   tempWidth = 40000;
      // } else if (length>600 && length<800) {

      // }

      this.props.toggleLoading(false);
      this.props.toggleShow(true);
      this.setState({
        titiks: result['data'],
      });
    } catch (error) {
      this.props.toggleLoading(false);
      this.props.toggleShow(false);
      toast.error(
        <span className="text-white text-break">{error.message}</span>,
        {}
      );

      // console.log(error);
    }
  }

  componentDidUpdate() {
    if (this.props.loading===true) {
      this.getLevelAirRiwayatApi();
    }
  }

  render() {
    return (
      <>
        {this.props.show===true ? (
          <>
            <div 
              style={{ 
                overflow: 'scroll',
              }}
            >
              <HighchartsReact 
                constructorType={'chart'}
                highcharts={Highcharts} 
                containerProps={{ 
                  // className: 'test',
                  style: {
                    height: '350px',
                    marginBottom: '20px',
                    width: '50000px',
                    // paddingRight: '14px',
                  }
                }}
                updateArgs={[true, true, true]}
                options={{
                  allowChartUpdate: true,
                  chart: {
                    type: 'spline',
                    borderColor: '#229BED',
                    borderWidth: 1,
                    borderRadius: 7,
                    events: {
                      beforePrint: function() {
                        document.body.classList.add('bg-white');
                      },
                      afterPrint: function() {
                        document.body.classList.remove('bg-white');
                      },
                    },
                  },
                  colors: [
                    '#346BE2',
                  ],
                  title: {
                    text: `<b>${this.props.lokasi.i0001}</b>`,
                  },
                  xAxis: {
                    type: 'datetime',
                    tickPixelInterval: 150
                  },
                  yAxis: {
                    title: {
                      text: 'Ketinggian Air (cm)'
                    },
                    plotLines: [{
                      value: 0,
                      width: 1,
                      color: '#808080'
                    }]
                  },
                  tooltip: {
                    formatter: function() {
                      return '<b>'+ this.series.name +'</b><br/>'+
                      Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', this.x) +'<br/>'+
                      Highcharts.numberFormat(this.y, 0);
                    },
                  },
                  plotOptions: {
                    spline: {
                      dataLabels: {
                        enabled: false,
                        allowOverlap: true,
                        format: "{y:.0f}",
                      },
                      enableMouseTracking: true,
                      turboThreshold: 2000,
                    },
                  },
                  legend: {
                    enabled: false,
                  },
                  exporting: {
                    enabled: true,
                    printMaxWidth: 1000,
                  },
                  series: [{
                    name: 'Data',
                    data: (function(component) {
                      var dataApi = component.state.titiks;
                      var data = [];

                      for (let i = 0; i < dataApi.length; i++) {
                        var tamp1 = dataApi[i]['c'];
                        var tamp2 = tamp1.replace(/-/g, "/");

                        data.push({
                          x: new Date(tamp2).getTime(),
                          y: parseInt(dataApi[i]['i0002']),
                        });
                      }
                      
                      return data;
                    })(this),
                  }],
                }} 
              />
            </div>
          </>
        ) : null}
      </>
    );
  }
};

export default Location;
