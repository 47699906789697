import React, { useEffect, useState } from 'react';
import { DateTimePicker } from 'react-tempusdominus-bootstrap';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

import Protected from '../../middlewares/admin/Protected';
import Layout from '../../layouts/admin/mains/Dashboard';
import Highchart from '../../components/charts/History';

import { 
  getLokasiAir, 
  postRiwayatPDF, 
} from '../../apis';

const Riwayat = () => {
  const [selected, setSelected] = useState({});
  const [lokasi, setLokasi] = useState({});
  const [lokasis, setLokasis] = useState([
    {
      id: 0,
      i0001: 'Pilih lokasi',
    }
  ]);
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const getLokasiAirApi = async() => {
    try {
      const result = await getLokasiAir();

      setLokasis((prevState) => [...prevState, ...result.data]);
    } catch (error) {
      toast.error(
        <span className="text-white text-break">{error.message}</span>,
        {}
      );
      // console.log(error);
    }
  }

  const lokasiOnChange = (e) => {
    const { value } = e.target;
    const found = lokasis.find((element) => (element.id==value));
    
    setSelected(found);
  }

  const startOnChange = (e) => {
    if (e.date) {
      const date = moment(e.date).format('YYYY-MM-DD HH:mm')+':00';

      setStart(date);
    } else {
      setStart("");
    }
  }

  const endOnChange = (e) => {
    if (e.date) {
      const date = moment(e.date).format('YYYY-MM-DD HH:mm')+':00';

      setEnd(date);
    } else {
      setEnd("");
    }
  }

  const cariOnClick = () => {
    const startIso = moment(start).toISOString();
    const endIso = moment(end).toISOString();

    if (loading==false && Object.entries(selected).length!=0 && start!='' && end!='') {
      if (moment(startIso).isBefore(endIso)===true) {
        if (moment(endIso).diff(startIso, 'minutes')<=60) {
          setLokasi(selected);
          setLoading(true);
        } else {
          toast.error(
            <span className="text-white text-break">Maksimal jangka waktu hanya 60 menit</span>,
            {}
          );
        }
      } else {
        toast.error(
          <span className="text-white text-break">Tanggal awal melewati tanggal akhir</span>,
          {}
        );
      }
    }
  }

  const toggleLoading = (value) => {
    setLoading(value);
  }

  const toggleShow = (value) => {
    setShow(value);
  }

  const pdfOnClick = async() => {
    if (Object.entries(lokasi).length!=0) {
      setLoadingPDF(true);

      try {
        const response = await postRiwayatPDF(lokasi.id, {
          start_datetime: start,
          end_datetime: end,
        });

        const disposition = response.headers['content-disposition'];
        var filename = "";

        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) { 
            filename = matches[1].replace(/['"]/g, '');
          }
        }
  
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        setLoadingPDF(false);
      } catch (error) {
        toast.error(
          <span className="text-white text-break">{error.message}</span>,
          {}
        );

        setLoadingPDF(false);
      }
    }
  }

  const setPlaceholder = () => {
    const el1 = document.getElementsByClassName('awal-input');
    const el2 = document.getElementsByClassName('akhir-input');

    if (el1.length>0 && el2.length>0) {
      const el1Cd1 = el1[0].childNodes[0];
      const el1Cd1Cd1 = el1Cd1.childNodes[0];
      const el2Cd1 = el2[0].childNodes[0];
      const el2Cd1Cd1 = el2Cd1.childNodes[0];
  
      document.getElementById(el1Cd1Cd1.id).placeholder = "Awal";
      document.getElementById(el2Cd1Cd1.id).placeholder = "Akhir";
    }
  }

  useEffect(() => {
    getLokasiAirApi();
    setPlaceholder();

    return () => {

    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Riwayat - Admin | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>

      <div className="pagetitle">
        <h1>Riwayat</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">Riwayat</li>
          </ol>
        </nav>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-12">
            <select className="form-select border-primary" aria-label="Default select example" value={selected.id}
              onChange={lokasiOnChange}
            >
              {lokasis.map((value, index) => {
                return (
                  <option key={index} value={value.id}>{value.i0001}</option>
                );
              })}
            </select>
          </div>
          <div className="col-12 mt-2">
            <div className="row">
              <div className="col-6">
                <DateTimePicker
                  className="tempus-custom border-primary awal-input"
                  useCurrent={true}
                  date={start}
                  format={"YYYY-MM-DD HH:mm"+":00"}
                  noIcon={true}
                  locale='id'
                  onChange={startOnChange}
                />
              </div>
              <div className="col-6">
                <DateTimePicker
                  className="tempus-custom border-primary akhir-input"
                  useCurrent={true}
                  date={end}
                  format={"YYYY-MM-DD HH:mm"+":00"}
                  noIcon={true}
                  locale='id'
                  onChange={endOnChange}
                />
              </div>
            </div>
          </div>
          <div className="col-12 mt-2">
            <button type="button" className="btn btn-primary w-100" disabled={loading}
              onClick={cariOnClick}
            >
              {loading===false ? (
                <>
                  <i className="bi bi-search me-1"></i>Cari
                </>
              ) : (
                <>
                  <i className="fa fa-circle-notch fa-spin me-1"></i>Loading
                </>
              )}
            </button>
          </div>
          <div className="col-12 mt-3">
            {show===true && (
              <>
                <button type="button" className="btn btn-primary mb-3" disabled={loadingPDF}
                  onClick={pdfOnClick}
                >
                  {loadingPDF===false ? (
                    <>
                      <i className="fas fa-download me-1"></i>PDF Tabel
                    </>
                  ) : (
                    <>
                      <i className="fa fa-circle-notch fa-spin me-1"></i>Loading
                    </>
                  )}
                </button>
              </>
            )}
            <Highchart 
              lokasi={lokasi}
              start={start}
              end={end}
              loading={loading}
              toggleLoading={toggleLoading}
              show={show}
              toggleShow={toggleShow}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Protected(Layout(Riwayat));
