import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import LoginPage from '../pages/Login';
import HomePage from '../pages/Home';
import AkunPage from '../pages/Akun';
import GrafikPage from '../pages/Grafik';
import RiwayatPage from '../pages/Riwayat';
import NotFoundPage from '../pages/NotFound';

const Index = () => {
  return (
    <>
      <Routes>
        <Route
          index={true}
          element={<Navigate to='/home' />}
        />
        <Route
          path='/login'
          element={<LoginPage />}
        />
        <Route
          path='/home'
          element={<HomePage />}
        />
        <Route
          path='/akun'
          element={<AkunPage />}
        />
        <Route
          path='/grafik'
          element={<GrafikPage />}
        />
        <Route
          path='/riwayat'
          element={<RiwayatPage />}
        />
        <Route
          path='/*'
          element={<NotFoundPage />}
        />
      </Routes>
    </>
  );
};

export default Index;
