import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';

import UnProtected from '../middlewares/UnProtected';
import Layout from '../layouts/mains/Auth';

import { 
  postLogin, 
} from '../apis/auth';

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const usernameOnChange = (e) => {
    const { value } = e.target;

    setUsername(value);
  }

  const passwordOnChange = (e) => {
    const { value } = e.target;

    setPassword(value);
  }

  const loginOnSubmit = async(e) => {
    e.preventDefault();

    setSubmitted(true);

    try {
      const result = await postLogin({
        username: username,
        password: password,
      });

      if (result.error===false) {
        Cookies.set('token', result.token, {
          expires: 1,
        });
        window.location.href = '/home';
      } else {
        toast.error(
          <span className="text-white text-break">{result.message}</span>,
          {}
        );
      }

      setSubmitted(false);
    } catch (error) {
      toast.error(
        <span className="text-white text-break">{error.message}</span>,
        {}
      );
      // console.log(error);
    }
  }

  return (
    <>
      <Helmet>
        <title>Login | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>

      <div className="container">

        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center px-4 px-lg-5">

                <div className="d-flex justify-content-center py-0">
                  <div className="d-flex align-items-center w-auto">
                    <img src={`${process.env.PUBLIC_URL}/images/logo_icon.png`} className="img-fluid" alt="" style={{ width: '150px', }}/>
                    {/* <h1 className="text-dark fw-bold">Moba Plg</h1> */}
                  </div>
                </div>
                <h3 className="text-center text-black fw-bold mb-4">Login</h3>

                <form className="row g-2"
                  onSubmit={loginOnSubmit}
                >
                  <div className="col-12">
                    {/* <label for="yourUsername" className="form-label">Username</label> */}
                    <div className="input-group has-validation">
                      <input type="text" name="username" className="form-control" id="username" placeholder="Username" 
                        value={username}
                        onChange={usernameOnChange}
                      />
                      {/* <div className="invalid-feedback"></div> */}
                    </div>
                  </div>

                  <div className="col-12">
                    {/* <label for="yourUsername" className="form-label">Password</label> */}
                    <div className="input-group has-validation">
                      <input type="password" name="username" className="form-control" id="password" placeholder="Password" 
                        value={password}
                        onChange={passwordOnChange} 
                      />
                      {/* <div className="invalid-feedback"></div> */}
                    </div>
                  </div>

                  <div className="col-12 mt-4">
                    <button type="submit" className="btn btn-primary w-100 mt-1" disabled={submitted}>
                      {submitted===false ? (
                        <>
                          <i className="fas fa-sign-in-alt me-1"></i>Login
                        </>
                      ) : (
                        <>
                          <i className="fa fa-circle-notch fa-spin me-1"></i>Loading
                        </>
                      )}
                    </button>
                  </div>
                </form>

              </div>
            </div>

            {/* <div className="credits">
              Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
            </div> */}

          </div>
        </section>

      </div>
    </>
  )
}

export default UnProtected(Layout(Login));