import { createContext, useState, useContext, useMemo } from 'react';

const AuthSelfContext = createContext({
  authSelf: false,
  setAuthSelf: () => {},
});

export const useAuthSelfContext = () => useContext(AuthSelfContext);

export const AuthSelfContextProvider = (props) => {
  const { children } = props;

  const [authSelf, setAuthSelf] = useState(false);

  const value = useMemo(
    () => ([ 
      authSelf, 
      setAuthSelf, 
    ]), 
    [authSelf]
  );

  return (
    <AuthSelfContext.Provider
      value={value}
    >
      {children}
    </AuthSelfContext.Provider>
  )
}