import { createContext, useState, useContext, useMemo } from 'react';

const SelfLoggedContext = createContext({
  selfLogged: {},
  setSelfLogged: () => {},
});

export const useSelfLoggedContext = () => useContext(SelfLoggedContext);

export const SelfLoggedContextProvider = (props) => {
  const { children } = props;

  const [selfLogged, setSelfLogged] = useState({});

  const value = useMemo(
    () => ([ 
      selfLogged, 
      setSelfLogged, 
    ]), 
    [selfLogged]
  );

  return (
    <SelfLoggedContext.Provider
      value={value}
    >
      {children}
    </SelfLoggedContext.Provider>
  )
}