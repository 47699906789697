import axios from 'axios';
import Cookies from 'js-cookie';

const apiUrl = process.env.REACT_APP_API_URL;

export const getLokasis = async() => {
  const { token } = Cookies.get();

  try {
    const response = await axios.get(`${apiUrl}/admin/lokasi`, 
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+token,
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getLokasi = async(id) => {
  const { token } = Cookies.get();

  try {
    const response = await axios.get(`${apiUrl}/admin/lokasi/${id}`, 
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+token,
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const putLokasi = async(id, data) => {
  const { token } = Cookies.get();

  try {
    const response = await axios.put(`${apiUrl}/admin/lokasi/${id}`, 
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+token,
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}