import React from 'react';
import { Helmet } from 'react-helmet';

import Protected from '../middlewares/Protected';
import Layout from '../layouts/mains/Dashboard';
import AkunDataTab from '../components/tabs/akun/Data';
import AkunPasswordTab from '../components/tabs/akun/Password';

const Akun = () => {
  return (
    <>
      <Helmet>
        <title>Akun | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>

      <div className="pagetitle">
        <h1>Akun</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">Akun</li>
          </ol>
        </nav>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-lg-6">
                <ul className="nav nav-tabs nav-tabs-bordered d-flex" id="borderedTabJustified" role="tablist">
                  <li className="nav-item flex-fill" role="presentation">
                    <button className="nav-link w-100 active" id="home-tab" data-bs-toggle="tab" data-bs-target="#bordered-justified-home" type="button" role="tab" aria-controls="home" aria-selected="true">Data</button>
                  </li>
                  <li className="nav-item flex-fill" role="presentation">
                    <button className="nav-link w-100" id="profile-tab" data-bs-toggle="tab" data-bs-target="#bordered-justified-profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Password</button>
                  </li>
                </ul>
                <div className="tab-content pt-2" id="borderedTabJustifiedContent">
                  <AkunDataTab />
                  <AkunPasswordTab />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Protected(Layout(Akun));