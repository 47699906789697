import React, { useEffect } from 'react';
import { ToastContainer, Zoom } from 'react-toastify';

import Footer from '../footers/Auth';

const Auth = (Component) => {
  const Render = (props) => {
    useEffect(() => {
      const main_js = document.getElementById('main-js');
      const script2 = document.createElement('script');

      if (main_js) {
        main_js.remove();
      }
      
      script2.setAttribute('id', 'main-js');
      script2.setAttribute('type', 'text/javascript');
      script2.setAttribute('src', '/assets/js/main.js');
      script2.defer = true;
      script2.async = true;
      document.body.appendChild(script2);
    
      return () => {
        
      };
    }, []);

    return (
      <>
        <main>
          <Component {...props} />
        </main>
        <Footer />
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={true}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={false}
          progress={undefined}
          theme='colored'
          transition={Zoom}
        />
      </>
    );
  }

  return Render;
};

export default Auth;
