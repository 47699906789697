import React from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

import { useSelfLoggedContext } from '../../../contexts/selfLogged';
import { postLogout } from '../../../apis/admin/auth';

const Dashboard = () => {
  const [selfLogged, setSelfLogged] = useSelfLoggedContext();

  const logoutOnClick = async() => {
    const result = await postLogout();

    if (result.error===false) {
      Cookies.remove('token');
      setSelfLogged({});
      window.location.href = '/admin/login';
    } else {
      toast.error(
        <span className="text-white text-break">{result.message}</span>,
        {}
      );
    }
  }

  return (
    <>
      <header id="header" className="header fixed-top d-flex align-items-center">
        <div className="d-flex align-items-center justify-content-between">
          <div className="logo d-flex align-items-center justify-content-center">
            <Link 
              to="/admin/home" 
            >
              {/* <img src="assets/img/logo.png" alt=""/> */}
              <span className="d-none d-lg-block text-black">Moba Plg</span>
            </Link>
          </div>
          <i className="bi bi-list toggle-sidebar-btn"></i>
        </div>
        
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item dropdown pe-3">
              <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                <img src={`${process.env.PUBLIC_URL}/images/pict_default_fix.jpg`} alt="Profile" className="rounded-circle"/>
                <span className="d-none d-md-block dropdown-toggle ps-2">{selfLogged.namaLengkap}</span>
              </a>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li className="dropdown-header">
                  <h6>{selfLogged.namaLengkap}</h6>
                  <span>{selfLogged.tingkat}</span>
                </li>
                <li>
                  <hr className="dropdown-divider"/>
                </li>

                <li>
                  <Link
                    to='/admin/akun'
                    className="dropdown-item d-flex align-items-center"
                  >
                    <i className="fas fa-user-cog"></i>
                    <span>Akun</span>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider"/>
                </li>

                {/* <li>
                  <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
                    <i className="bi bi-gear"></i>
                    <span>Account Settings</span>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider"/>
                </li>

                <li>
                  <a className="dropdown-item d-flex align-items-center" href="pages-faq.html">
                    <i className="bi bi-question-circle"></i>
                    <span>Need Help?</span>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider"/>
                </li> */}

                <li>
                  <button type="button" className="dropdown-item d-flex align-items-center" href="#"
                    onClick={logoutOnClick}
                  >
                    <i className="fas fa-sign-out-alt"></i>
                    <span>Logout</span>
                  </button>
                </li>

              </ul>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Dashboard;
