import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import $ from 'jquery';
import DataTable from 'datatables.net-bs5';

import Protected from '../../middlewares/admin/Protected';
import Layout from '../../layouts/admin/mains/Dashboard';
import AddModal from '../../components/modals/user/Add';
import EditModal from '../../components/modals/user/Edit';
import DeleteModal from '../../components/modals/user/Delete';
import PasswordModal from '../../components/modals/user/Password';

import { 
  getUsers, 
} from '../../apis/admin/user';

$.DataTable = DataTable;

const User = () => {
  const [users, setUsers] = useState([]);

  const getUsersApi = async() => {
    try {
      const result = await getUsers();
// console.log(result);
      if (result.error==false) {
        setUsers(result.data);

        createDataTable();
      } else {
        toast.error(
          <span className="text-white">{result.message}</span>,
          {}
        );
      }
    } catch (error) {
      toast.error(
        <span className="text-white text-break">{error.message}</span>,
        {}
      );
      // console.log(error);
    }
  }

  const createDataTable = () => {
    $(document).ready(() => {
      $('#user_table').DataTable({
          destroy: true,
          lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
          // scrollX: true,
          columnDefs: [{
            targets: [4],
            orderable: false,
          }]
      });
    });
  }

  const destroyDataTable = () => {
    $(document).ready(() => {
      $('#user_table').DataTable().destroy();
    });
  }

  const refreshOnClick = () => {
    destroyDataTable();
    getUsersApi();
  }

  useEffect(() => {
    getUsersApi();
  
    return () => {
      
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>User - Admin | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>

      <div className="pagetitle">
        <h1>User</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">User</li>
          </ol>
        </nav>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-12">
            {/* <div className="card">
              <div className="card-body pt-4"> */}
                <div className="row mb-2">
                  <div className="col-12">
                    <button type="button" id="add_button" className="btn btn-success btn-sm me-1">
                      <i className="fas fa-plus me-1"></i>Tambah
                    </button>
                    <button type="button" id="refresh_button" className="btn btn-primary btn-sm"
                      onClick={refreshOnClick}
                    >
                      <i className="fas fa-sync-alt me-1"></i>Refresh
                    </button>
                  </div>
                </div>
                <table id='user_table' className="table table-bordered mb-0 bg-white">
                  <thead>
                    <tr className="text-center">
                      <th>No</th>
                      <th>Nama Lengkap</th>
                      <th>Username</th>
                      <th>Privileges</th>
                      {/* <th>Tingkat</th> */}
                      <th>
                        Aksi
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((value, index) => {
                      return (
                        <tr
                          key={index}
                        >
                          <td>{index+1}</td>
                          <td>{value.i0001}</td>
                          <td>{value.i0002}</td>
                          <td>{value.i0004}</td>
                          {/* <td>{value.i0005}</td> */}
                          <td>
                            <button type="button" className="password_button btn btn-danger btn-sm me-1 d-inline-flex align-items-center" data-id={value.id}>
                              <i className="fas fa-lock me-1"></i>Password
                            </button>
                            <button type="button" className="edit_button btn btn-warning btn-sm me-1 d-inline-flex align-items-center" data-id={value.id}>
                              <i className="fas fa-edit me-1"></i>Ubah
                            </button>
                            <button type="button" className="delete_button btn btn-danger btn-sm d-inline-flex align-items-center" data-id={value.id}>
                              <i className="fas fa-trash me-1"></i>Hapus
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              {/* </div>
            </div> */}
          </div>
        </div>
      </section>
      <AddModal 
        getUsersApi={getUsersApi}
        destroyDataTable={destroyDataTable}
      />
      <EditModal 
        getUsersApi={getUsersApi}
        destroyDataTable={destroyDataTable}
      />
      <DeleteModal
        getUsersApi={getUsersApi}
        destroyDataTable={destroyDataTable}
      />
      <PasswordModal />
    </>
  );
};

export default Protected(Layout(User));
