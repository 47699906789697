import axios from 'axios';

const apiKey = 'Hi92983huiU3g5bkH5BVGv3daf2F5e2A';
const apiUrl = process.env.REACT_APP_API_URL;

export const getLokasiAir = async() => {
  try {
    const params = new URLSearchParams();
    params.append('api_key', apiKey);
    
    const response = await axios.post("https://api.hagenav.com/get_lokasi_air.php", 
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getLevelAir = async(data) => {
  try {
    const params = new URLSearchParams();
    params.append('api_key', apiKey);
    params.append('position', data.position);
    
    const response = await axios.post("https://api.hagenav.com/get_level_air.php", 
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getLevelAirMaxMin = async(data) => {
  try {
    const params = new URLSearchParams();
    params.append('api_key', apiKey);
    params.append('position', data.position);
    params.append('start_datetime', data.start_datetime);
    params.append('end_datetime', data.end_datetime);
    
    const response = await axios.post("https://api.hagenav.com/get_level_air_max_min.php", 
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const postLoginAir = async(data) => {
  try {
    const params = new URLSearchParams();
    params.append('api_key', apiKey);
    params.append('username', data.username);
    params.append('password', data.password);
    
    const response = await axios.post("https://api.hagenav.com/post_login_air.php", 
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getLevelAirRiwayat = async(data) => {
  try {
    const params = new URLSearchParams();
    params.append('api_key', apiKey);
    params.append('position', data.position);
    params.append('start_datetime', data.start_datetime);
    params.append('end_datetime', data.end_datetime);
    
    const response = await axios.post("https://api.hagenav.com/get_level_air_riwayat.php", 
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const postRiwayatPDF = async(position, data) => {
  try {
    // const response = await axios.post(`http://localhost:8888/api-moba-plg/riwayat/pdf/${position}`, 
    const response = await axios.post(`${apiUrl}/riwayat/pdf/${position}`, 
      data,
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        }
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
}