import React from 'react';
import moment from 'moment';

const Auth = () => {
  const year = moment().format('YYYY');
  
  return (
    <>
      <footer className="d-flex flex-column align-items-center justify-content-center mb-3"
        style={{ 
          position: 'fixed', 
          bottom: '0%',
          width: '100%',
          fontSize: '14px',
        }}
      >
        <div className="copyright">
          Copyright &copy; {year} <b>Moba Plg</b> By <b>HAV</b>
        </div>
        <div className="credits">
          <strong>NiceAdmin</strong> Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
        </div>
      </footer>
    </>
  )
}

export default Auth;