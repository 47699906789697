import React, { useEffect, useState } from 'react';
import { ToastContainer, Zoom } from 'react-toastify';

import Header from '../headers/Dashboard';
import Sidebar from '../sidebars/Dashboard';
import Footer from '../footers/Dashboard';

import { useAuthSelfContext } from '../../../contexts/authSelf';

const Dashboard = (Component) => {
  const Render = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [authSelf, ,setAuthSelf] = useAuthSelfContext();

    useEffect(() => {
      if (authSelf===true) {
        if (loaded===true) {
          const main_js = document.getElementById('main-js');
          const script = document.createElement('script');
  
          if (main_js) {
            main_js.remove();
          }
          
          script.setAttribute('id', 'main-js');
          script.setAttribute('type', 'text/javascript');
          script.setAttribute('src', '/assets/js/main.js');
          script.defer = true;
          script.async = true;
          document.body.appendChild(script);
        } else {
          setLoaded(true);
        }
      }

      return () => {

      };
    }, [
      loaded,
      authSelf,
    ]);

    return (
      <>
        <Header />
        <Sidebar />
        <main id="main" className="main"
          style={{ 
            minHeight: 'calc(100vh - 60px - 86.5px)',
          }}
        >
          {(loaded===true && authSelf===true) && (
            <Component {...props}/>
          )}
        </main>
        <Footer />
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={true}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={false}
          progress={undefined}
          theme='colored'
          transition={Zoom}
        />
      </>
    );
  }

  return Render;
};

export default Dashboard;
