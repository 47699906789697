import React, { createRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

import {
  getLevelAir,
  getLevelAirRiwayat,
} from '../../apis/index';

class Grafik extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      levelAirs: [],
    };
    this.timerRef = createRef(null);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // if (nextProps.total!==prevState.total) {
      
    // }
    return null;
  }

  async getLevelAirApi() {
    const result = await getLevelAir({
      position: this.props.lokasi.id,
    });

    return result;
  }

  async timerLoad(series, tooltip) {
    try {
      // const result = await getLevelAir({
      //   position: this.props.lokasi.id,
      // });
      const { data } = null;
      var x = new Date().getTime();
      var y = 0;

      if (data!=null) {
        y = parseInt(data['i0002']);
        // const seriesData = series.data;

        series.addPoint([x, y], true, true);
        // tooltip.refresh(series.data[seriesData.length-1]);
      } else {
        // clearInterval(this.timerRef.current);
        return;
      }
    } catch (error) {
      // console.log(error);
    }
  }

  async getLevelAirRiwayatApi() {
    var start = moment().subtract(1, 'minutes').format('YYYY-MM-DD HH:mm:ss');
    var end = moment().format('YYYY-MM-DD HH:mm:ss');

    try {
      const result = await getLevelAirRiwayat({
        'position': this.props.lokasi.id,
        'start_datetime': start,
        'end_datetime': end,
      });
      const { data } = result;

      if (data!=null) {
        var temp = [];

        if (data.length>=19) {
          for (let i = 0; i < data.length; i++) {
            temp.push({
              x: (moment(data[i]['c']).valueOf()),
              y: parseInt(data[i]['i0002']),
            });
          }

          setTimeout(() => {
            this.setState({
              levelAirs: temp,
            });
          }, 3000);
        } else {
          temp = [];
          var time = (new Date()).getTime();

          for (let i = -19; i <= 0; i++) {
            temp.push({
              x: time + i * 1000,
              // y: Math.random()*(250-200)+200,
              y: 0,
            });
          }

          setTimeout(() => {
            this.setState({
              levelAirs: temp,
            });
          }, 3000);
        }
      }
    } catch (error) {
      // console.log(error);
    }
  }

  componentDidUpdate(prevProps, nextState) {
    if (this.state.show===false && Object.entries(this.props.lokasi).length!==0) {
      this.setState({
        show: true,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerRef.current);
  }

  render() {
    return (
      <>
        {this.state.show===true ? (
          <HighchartsReact 
            highcharts={Highcharts} 
            containerProps={{ 
              // className: 'test',
              style: {
                height: '350px',
                marginBottom: '20px',
                // paddingRight: '14px',
              }
            }}
            updateArgs={[true, true, true]}
            options={{
              allowChartUpdate: true,
              chart: {
                type: 'spline',
                events: {
                  load: (function(component) {
                    return function() {
                      const series = this.series[0];
                      const tooltip = this.tooltip;
                      var x = new Date().getTime();
                      var y;
                      var run = true;

                      component.timerRef.current = setInterval(async function() {
                        try {
                          if (run==true) {
                            run = false;
                            const result = await component.getLevelAirApi();
                            run = true;
                            const { data } = result;
                            
                            if (data!=null) {
                              y = parseInt(data['i0002']);
                              // const seriesData = series.data;
                      
                              // tooltip.refresh(series.data[seriesData.length-1]);
                            } else {
                              clearInterval(component.timerRef.current);

                              return;
                            }
                          }
                        } catch (error) {
                          // console.log(error);
                          clearInterval(component.timerRef.current);

                          throw error;
                        }

                        if (run==true) {
                          series.addPoint([x, y], true, true);
                        }
                        // component.timerLoad(series, tooltip);
                      }, 1000);
                    }
                  })(this),
                },
                borderColor: '#229BED',
                borderWidth: 1,
                borderRadius: 7,
              },
              colors: [
                '#346BE2',
              ],
              title: {
                text: `<b>${this.props.lokasi.i0001}</b>`,
              },
              xAxis: {
                type: 'datetime',
                tickPixelInterval: 150
              },
              yAxis: {
                title: {
                  text: 'Ketinggian Air (cm)'
                },
                plotLines: [{
                  value: 0,
                  width: 1,
                  color: '#808080'
                }]
              },
              tooltip: {
                formatter: function() {
                  return '<b>'+ this.series.name +'</b><br/>'+
                  Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', this.x) +'<br/>'+
                  Highcharts.numberFormat(this.y, 0);
                },
              },
              plotOptions: {
                spline: {
                  dataLabels: {
                    enabled: false,
                    allowOverlap: true,
                    format: "{y:.0f}",
                    // color: "#000000",
                    // borderColor: "",
                  },
                  enableMouseTracking: true,
                },
              },
              legend: {
                enabled: false,
              },
              exporting: {
                enabled: false,
              },
              series: [{
                name: 'Data',
                data: (function(component) {
                  var data = [];
                  var time = (new Date()).getTime();
        
                  for (let i = -19; i <= 0; i++) {
                    data.push({
                      x: time + i * 1000,
                      // y: Math.random()*(250-200)+200,
                      y: 0,
                    });
                  }

                  return data;
                  // return component.state.levelAirs;
                })(this),
              }],
            }} 
          />
        ) : null}
      </>
    );
  }
};

export default Grafik;
