import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.min.css';
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/scss/bootstrap.scss"; // Or the one above.
import "font-awesome/css/font-awesome.css";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";
// import "tempusdominus-bootstrap/src/sass/tempusdominus-bootstrap-build.scss"; // Or the one above.
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';

import IndexRoute from './routes/Index';
import AdminRoute from './routes/Admin';

import { AppLoadedContextProvider } from './contexts/appLoaded';
import { SelfLoggedContextProvider } from './contexts/selfLogged';
import { AuthSelfContextProvider } from './contexts/authSelf';

const App = () => {
  return (
    <>
      <AppLoadedContextProvider>
        <SelfLoggedContextProvider>
          <AuthSelfContextProvider>  
            <div data-reactid='.gs8n23h45.0'>
              <BrowserRouter>
                <Routes>
                  <Route
                    index={true}
                    path='/*'
                    element={<IndexRoute />}
                  />
                  <Route
                    path='/admin/*'
                    element={<AdminRoute />}
                  />
                </Routes>
              </BrowserRouter>
            </div>
          </AuthSelfContextProvider>
        </SelfLoggedContextProvider>
      </AppLoadedContextProvider>
    </>
  );
}

export default App;
