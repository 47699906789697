import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';

import {
  postUser,
} from '../../../apis/admin/user';

const Add = (props) => {
  const { getUsersApi, destroyDataTable } = props;

  const modal = useRef(null);
  const [namaLengkap, setNamaLengkap] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [privileges, setPrivileges] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const namaLengkapOnChange = (e) => {
    const { value } = e.target;

    setNamaLengkap(value);
  }

  const usernameOnChange = (e) => {
    const { value } = e.target;

    setUsername(value);
  }

  const passwordOnChange = (e) => {
    const { value } = e.target;

    setPassword(value);
  }

  const privilegesOnChange = (e) => {
    const { value } = e.target;

    setPrivileges(value);
  }

  const addOnSubmit = async(e) => {
    e.preventDefault();

    setSubmitted(true);
    
    try {
      const result = await postUser({
        nama_lengkap: namaLengkap,
        username: username,
        password: password,
        privileges: privileges,
      });
// console.log(result);
      if (result.error===false) {
        hideModal();

        toast.success(
          <span className="text-white">{result.message}</span>,
          {}
        );
        
        destroyDataTable();
        getUsersApi();
      } else {
        toast.error(
          <span className="text-white">{result.message}</span>,
          {}
        );
      }

      setSubmitted(false);
    } catch (error) {
      toast.error(
        <span className="text-white text-break">{error.message}</span>,
        {}
      );
      // console.log(error);
      setSubmitted(false);
    }
  }

  const listener = (e) => {
    const element = e.target;

    if (element.tagName=='BUTTON' && element.id=="add_button") {
      modal.current.show();
    }
  }

  const hideModal = () => {
    modal.current.hide();

    setTimeout(() => {
      setNamaLengkap("");
      setUsername("");
      setPrivileges("");
      setPassword("");
    }, 1000);
  }

  useEffect(() => {
    const elementModal = document.getElementById('add_modal');
    modal.current = new Modal(elementModal, {
      keyboard: true,
    });

    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener)
    }
  }, []);

  return (
    <>
      <div className="modal fade" id="add_modal" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold">Formulir Tambah</h5>
              <div className="me-1"
                style={{ 
                  cursor: 'pointer',
                }}
                onClick={hideModal}
              >
                <i className="fas fa-times"></i>
              </div>
            </div>
            <form
              onSubmit={addOnSubmit}
            >
              <div className="modal-body">
                <div className="row mb-3">
                  <label htmlFor="add_nama_lengkap" className="col-sm-4 col-form-label">Nama Lengkap</label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control" id="add_nama_lengkap" placeholder="Nama Lengkap" value={namaLengkap}
                      onChange={namaLengkapOnChange}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="add_username" className="col-sm-4 col-form-label">Username</label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control" id="add_username" placeholder="Username" value={username}
                      onChange={usernameOnChange}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="add_password" className="col-sm-4 col-form-label">Password</label>
                  <div className="col-sm-8">
                    <input type="password" className="form-control" id="add_password" placeholder="Password" value={password}
                      onChange={passwordOnChange}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="add_privileges" className="col-sm-4 col-form-label">Privileges</label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control" id="add_privileges" placeholder="Privileges" value={privileges}
                      onChange={privilegesOnChange}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline-danger btn-sm" 
                  onClick={hideModal}
                >
                  <i className="fas fa-times me-1"></i>Tutup
                </button>
                <button type="submit" className="btn btn-success btn-sm" disabled={submitted}>
                  {submitted===false ? (
                    <>
                      <i className="fas fa-check me-1"></i>Simpan
                    </>
                  ) : (
                    <>
                      <i className="fa fa-circle-notch fa-spin me-1"></i>Loading
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Add;