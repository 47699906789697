import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

import Protected from '../middlewares/Protected';
import Layout from '../layouts/mains/Dashboard';
import Highchart from '../components/charts/Grafik';

import { 
  getLokasiAir, 
} from '../apis';

const Grafik = () => {
  const [selected, setSelected] = useState({});
  const [lokasi, setLokasi] = useState({});
  const [lokasis, setLokasis] = useState([
    {
      id: 0,
      i0001: 'Pilih lokasi',
    }
  ]);

  const getLokasiAirApi = async() => {
    try {
      const result = await getLokasiAir();

      setLokasis((prevState) => [...prevState, ...result.data]);
    } catch (error) {
      toast.error(
        <span className="text-white text-break">{error.message}</span>,
        {}
      );
      // console.log(error);
    }
  }

  const lokasiOnChange = (e) => {
    const { value } = e.target;
    const found = lokasis.find((element) => (element.id==value));
    
    setSelected(found);
    
    if (parseInt(value)!==0) {
      setLokasi(found);
    }
  }

  useEffect(() => {
    getLokasiAirApi();

    return () => {

    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Grafik | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>

      <div className="pagetitle">
        <h1>Grafik</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">Grafik</li>
          </ol>
        </nav>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-12">
            <select className="form-select border-primary" aria-label="Default select example" value={selected.id}
              onChange={lokasiOnChange}
            >
              {lokasis.map((value, index) => {
                return (
                  <option key={index} value={value.id}>{value.i0001}</option>
                );
              })}
            </select>
          </div>
          <div className="col-12 mt-3">
            <Highchart 
              lokasi={lokasi}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Protected(Layout(Grafik));
