import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';

import { 
  getLevelAir,
  getLevelAirMaxMin,
} from '../../apis';

const Home = (props) => {
  const { value } = props;
  const { id, i0001: nama } = value;
  
  const mountedRef = useRef(false);
  const timerNow = useRef(null);
  const timerMaxMin = useRef(null);
  const [now, setNow] = useState(null);
  const [max, setMax] = useState(null);
  const [min, setMin] = useState(null);

  const getLevelAirTimer = async() => {
    const getData = async() => {
      try {
        const response = await getLevelAir({
          position: id,
        });

        if (response.data!=null) {
          const data = response.data;

          if (mountedRef.current===true) {
            setNow(data.i0002);
          }
        } else {
          setNow(null);
          // clearInterval(timer.current);
          // return;
        }
      } catch (error) {
        // console.log(error);
        return;
      }
    }

    await getData();

    timerNow.current = setInterval(async function() {
      await getData();
    }, 3000);
  }

  const getLevelAirMaxMinTimer = async() => {
    const getData = async() => {
      try {
        const response = await getLevelAirMaxMin({
          position: id,
          start_datetime: (moment().format('YYYY-MM-DD'))+' 00:00:00',
          end_datetime: (moment().format('YYYY-MM-DD'))+' 23:59:59',
        });

        if (response.data!=null) {
          const data = response.data;

          if (mountedRef.current===true) {
            setMax(data.max);
            setMin(data.min);
          }

          // if (mountedRef.current===true) {
          //   await getData();
          // }

          // return;
        } else {
          // clearInterval(timer.current);
          // return;
        }
      } catch (error) {
        // console.log(error);
        return;
      }
    }

    await getData();

    timerMaxMin.current = setInterval(async function() {
      await getData();
    }, 60*1000);
  }

  useEffect(() => {
    mountedRef.current = true;

    getLevelAirTimer();
    getLevelAirMaxMinTimer();

    return () => {
      mountedRef.current = false;

      clearInterval(timerNow.current);
      clearInterval(timerMaxMin.current);
    };
  }, []);

  return (
    <>
      <div className="col-12 col-md-6">
        <div className="row mb-1">
          <div className="col-12">
            <div className="text-center">
              <h4 className="fw-bold text-black">{nama}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 d-flex">
            <div className="card bg-primary text-white border border-white border-3 shadow flex-fill">
              <div className="card-body pt-3">
                <div className="d-flex justify-content-center align-items-center h-100">
                  <div className="d-flex flex-column">
                    <h5 className="fw-bold mb-0">Sekarang</h5>
                    <span className="text-center">{now!==null ? now+' cm' : '-'}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-12">
                <div className="card bg-danger text-white border border-white border-3 shadow mb-3">
                  <div className="card-body p-2">
                    <div className="d-flex justify-content-center">
                      <div className="d-flex flex-column">
                        <h5 className="fw-bold mb-0">Tertinggi</h5>
                        <span className="text-center">{max!==null ? max+' cm' : '-'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card bg-success text-white border border-white border-3 shadow">
                  <div className="card-body p-2">
                    <div className="d-flex justify-content-center">
                      <div className="d-flex flex-column">
                        <h5 className="fw-bold mb-0">Terendah</h5>
                        <span className="text-center">{min!==null ? min+' cm' : '-'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home;