import React, { Fragment, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { toast } from 'react-toastify';

import Protected from '../../middlewares/admin/Protected';
import Layout from '../../layouts/admin/mains/Dashboard';
import Col from '../../components/cols/Home';
// import Highchart from '../components/charts/Location';

import {
  getLokasiAir,
} from '../../apis/index';

const Home = () => {
  const [lokasis, setLokasis] = useState([]);

  const getLokasiAirApi = async() => {
    try {
      const result = await getLokasiAir();

      setLokasis(result.data);
    } catch (error) {
      toast.error(
        <span className="text-white text-break">{error.message}</span>,
        {}
      );
      // console.log(error);
    }
  }
  
  useEffect(() => {
    getLokasiAirApi();

    return () => {

    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Home - Admin | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>

      <div className="pagetitle">
        <h1>Home</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">Home</li>
          </ol>
        </nav>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-12">
            <div className="row">
              {lokasis.map((value, index) => {
                return (
                  <Fragment
                    key={index}
                  >
                    <Col
                      value={value}
                    />
                  </Fragment>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Protected(Layout(Home));
