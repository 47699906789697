import { createContext, useState, useContext, useMemo } from 'react';

const AppLoadedContext = createContext({
  appLoaded: false,
  setAppLoaded: () => {},
});

export const useAppLoadedContext = () => useContext(AppLoadedContext);

export const AppLoadedContextProvider = (props) => {
  const { children } = props;

  const [appLoaded, setAppLoaded] = useState(false);

  const value = useMemo(
    () => ([ 
      appLoaded, 
      setAppLoaded, 
    ]), 
    [appLoaded]
  );

  return (
    <AppLoadedContext.Provider
      value={value}
    >
      {children}
    </AppLoadedContext.Provider>
  )
}