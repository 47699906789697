import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

import { useAppLoadedContext } from '../../contexts/appLoaded';

const UnProtected = (Component) => {
  const Render = (props) => {
    const navigate = useNavigate();
    const [appLoaded, setAppLoaded] = useAppLoadedContext();

    const auth = () => {
      const { token } = Cookies.get();

      if (token) {
          navigate('/admin/home');
      } else {
        if (appLoaded===false) {
          setAppLoaded(true);
        }
      }
    }

    useEffect(() => {
      auth();
    
      return () => {
        
      }
    }, []);

    return (
      <>
        {appLoaded===true && (
          <Component {...props} />
        )}
      </>
    )
  }

  return Render;
}

export default UnProtected;